import "../scss/index.scss";

import "lazysizes";
import 'focus-visible';

import './components/transitions';

import './components/menu';
import './components/logo';
import './components/showcase';
import './components/lightbox';

document.addEventListener('lazybeforeunveil', function(e){
  e.target.parentNode.classList.add("is-loaded");
});
