class GlobalPagination {

  constructor() {
    this.pagination = document.querySelector('.js-global-pagination'),
    this.prevLink   = this.pagination.querySelector('a[rel="prev"]'),
    this.prevLabel  = this.prevLink.querySelector('.pagination-label-text'),
    this.nextLink   = this.pagination.querySelector('a[rel="next"]'),
    this.nextLabel  = this.nextLink.querySelector('.pagination-label-text');

    this.isPrevVisible = false,
    this.isNextVisible = false;

    document.documentElement.classList.add('has-global-pagination');
    this.pagination.removeAttribute('aria-hidden');


    // fix for overscrolling in safari
    let _lastScrollTop = null;

    addEventListener('scroll', function() {
  
      let scrollTop = pageYOffset;
  
      if(_lastScrollTop >= 0 && scrollTop < 0) {
        document.documentElement.classList.add('is-scrolled-negative');
      } else if(_lastScrollTop < 0 && scrollTop >= 0) {
        document.documentElement.classList.remove('is-scrolled-negative');
      }
  
      _lastScrollTop = scrollTop;
    });
  }

  showPrev(url, label) {
    this.isPrevVisible = true;
    this.prevLink.setAttribute('href', url);
    this.prevLabel.innerHTML = label;
    this.prevLink.classList.remove('is-hidden');
  }

  hidePrev() {
    this.isPrevVisible = false;
    this.prevLink.classList.add('is-hidden');
  }

  showNext(url, label) {
    this.isNextVisible = true;
    this.nextLink.setAttribute('href', url);
    this.nextLabel.innerHTML = label;
    this.nextLink.classList.remove('is-hidden');
  }

  hideNext() {
    this.isNextVisible = false;
    this.nextLink.classList.add('is-hidden');
  }

  update(container) {
    const newPrevLink = container.querySelector('.pagination a[rel="prev"]');
    const newNextLink = container.querySelector('.pagination a[rel="next"]');

    if(newPrevLink) {
      const newPrevLabel = newPrevLink.querySelector('.pagination-label-text');
      this.showPrev(newPrevLink.getAttribute('href'), newPrevLabel.innerHTML);
    } else {
      this.hidePrev();
    }

    if(newNextLink) {
      const newNextLabel = newNextLink.querySelector('.pagination-label-text');
      this.showNext(newNextLink.getAttribute('href'), newNextLabel.innerHTML);
    } else {
      this.hideNext();
    }
  }
}

export default GlobalPagination;