/* =====  Menu  ============================================================= */

import Barba from 'barba.js';
import GlobalPagination from './globalpagination';
// import 'prismjs'; ///prism.js
// import anime from 'animejs';

// Prism.manual = true;

// const contentEl = document.querySelector('.content');
// if (contentEl) {
//   Prism.highlightAllUnder(contentEl);
// }

if('scrollRestoration' in window.history) {
  history.scrollRestoration = 'manual';
}

const PAGE_TRANSITION_DURATION = 350;
const PAGE_TRANSITION_DELAY    = 20;

const initialOrigin    = window.location.origin;
const initialHostname  = window.location.hostname;
const contentOverlay   = document.querySelector('.content-overlay');


let pagination = new GlobalPagination();
pagination.update(document.querySelector('.barba-container'));

const FadeTransition = Barba.BaseTransition.extend({

  oldPaginationPrev: null,
  oldPaginationNext: null,
  loadingIndicatorTimer: null,

  start() {

    this.startLoadingIndicatorTimer();

    return Promise
      .all([
        this.fadeOut(),
        this.newContainerLoading,
      ])
      .then(this.updateMenu.bind(this))
      .then(this.fixNewContainerLinks.bind(this))
      .then(this.fadeIn.bind(this))
      .then(this.finish.bind(this));
  },

  finish() {
    this.done();
  },

  startLoadingIndicatorTimer() {
    if(this.loadingIndicatorTimer) {
      clearTimeout(this.loadingIndicatorTimer);
    }

    this.loadingIndicatorTimer = setTimeout(function() {
      contentOverlay.classList.add('is-loading-indicator-visible');
    }, 1500);
  },

  stopLoadingIndicatorTimer() {

    if(this.loadingIndicatorTimer) {
      clearTimeout(this.loadingIndicatorTimer);
    }

    contentOverlay.classList.remove('is-loading-indicator-visible');
  },

  fixNewContainerLinks() {
    return new Promise((resolve) => {

      if(initialHostname !== 'localhost') {
        resolve();
        return;
      }

      const fix = (links) => {
        for(let i = 0, l = links.length, element; i < l; i++) {
          element = links[i];
          if(element.hostname === 'fabianmichael.test') {
            element.href = initialOrigin + element.href.substring(element.origin.length);
          }
        }
      }

      fix(this.newContainer.querySelectorAll('[href]'))
      fix(document.querySelectorAll('.menu [href]'));

      resolve();
    });
  },

  updateMenu() {
    return new Promise((resolve) => {

      const currentUrl  = Barba.HistoryManager.currentStatus().url;

      Barba.BaseCache.get(currentUrl).then(
        (html) => {
          const doc     = document.createRange().createContextualFragment(html);
          const newMenu = doc.querySelector('.menu');
          const oldMenu = document.querySelector('.menu');

          if(oldMenu && newMenu) {
            oldMenu.innerHTML = newMenu.innerHTML;
          }

          resolve();
        },
        () => resolve()
      );

      resolve();

    });
  },

  // ---  Transitions  ---------------------------------------------------- //

  fadeOut() {
    const elements = this.oldContainer.querySelectorAll('[data-animated]');
    const nextUrl  = Barba.HistoryManager.currentStatus().url;
    const prev     = this.oldContainer.querySelector('[rel="prev"]');
    const next     = this.oldContainer.querySelector('[rel="next"]');

    let animation;

    // add directional transition if needed

    if(prev && nextUrl === prev.href) {
      animation = 'slideOutRight';
    } else if(next && nextUrl === next.href) {
      animation = 'slideOutLeft';
    }

    // store pagination elements for fadeIn animation

    this.oldPaginationPrev = this.oldContainer.querySelector('.pagination a[rel="prev"]');
    this.oldPaginationNext = this.oldContainer.querySelector('.pagination a[rel="next"]');

    return (new Promise(function(resolve) {

      if(animation) {
        for(let i = 0, l = elements.length; i < l; i++) {
          elements[i].classList.add('animated', animation);
        }
      }

      contentOverlay.style.visibility = 'visible';
      contentOverlay.style.opacity = 1;

      setTimeout(() => {
        document.documentElement.classList.add('is-transitioning');
        window.scrollTo(0, 0);
        resolve();
      }, PAGE_TRANSITION_DURATION);

    }));
  },

  fadeIn: function() {

    // update pagination
    pagination.update(this.newContainer);

    setTimeout(this.stopLoadingIndicatorTimer.bind(this), 350);

    // content transition

    const elements = this.newContainer.querySelectorAll('[data-animated]');

    this.oldContainer.style.display = 'none';

    // const contentEl = this.newContainer.querySelector('.content');
    // if (contentEl) {
    //   Prism.highlightAllUnder(contentEl);
    // }

    let animation = false;

    if(this.oldContainer.getAttribute('data-namespace') === this.newContainer.getAttribute('data-namespace')) {
      const nextUrl   = Barba.HistoryManager.currentStatus().url;
      const prev      = this.oldContainer.querySelector('[rel="prev"]');
      const next      = this.oldContainer.querySelector('[rel="next"]');

      if(prev && nextUrl === prev.href) {
        animation = 'slideInLeft';
      } else if(next && nextUrl === next.href) {
        animation = 'slideInRight';
      }
    }

    // color theme transition

    const oldTheme = this.oldContainer.getAttribute('data-theme');
    const newTheme = this.newContainer.getAttribute('data-theme');

    return (new Promise((resolve) => {
      setTimeout(() => {

        let isTransitioning = true;


        requestAnimationFrame(() => {

          contentOverlay.style.opacity = 0;

          this.newContainer.style.visibility = 'visible';

          if(oldTheme !== newTheme) {
            document.documentElement.classList.remove('-theme-' + oldTheme);
            document.documentElement.classList.add('-theme-' + newTheme);
          }


          if(animation) {
            for(let i = 0, l = elements.length; i < l; i++) {
              elements[i].classList.add('animated', animation);
            }
          }


          const $theme = document.head.querySelector('meta[name="theme-color"]');
          if ($theme) {
            const update_theme = () => {
              const bg = getComputedStyle(document.documentElement).backgroundColor;
              $theme.content = bg;
              if (isTransitioning) {
                requestAnimationFrame(update_theme);
              }
            }
            update_theme();
          }

          this.newContainer.classList.add('is-visible');

          setTimeout(() => {
            requestAnimationFrame(() => {
              contentOverlay.style.visibility = 'hidden';

              if(animation) {
                for(let i = 0, l = elements.length; i < l; i++) {
                  elements[i].classList.remove('animated', animation);
                }
              }
              document.documentElement.classList.remove('is-transitioning');
              isTransitioning = false;
              resolve();
            });
          }, PAGE_TRANSITION_DURATION);
        });
      });
    }));
  },
});

Barba.Pjax.Dom.wrapperId = 'content';
Barba.Pjax.getTransition = () => FadeTransition;

Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
Barba.Pjax.preventCheck = (evt, element) => {

  if(!Barba.Pjax.originalPreventCheck(evt, element)) {
    return false;
  }

  // No need to check for element.href -
  // originalPreventCheck does this for us! (and more!)
  if(element.pathname !== "/" && /\/.*\..*$/.test(element.pathname)) {
    return false;
  }

  return true;
};

Barba.Dispatcher.on('linkClicked', function(link, e) {
  try {
    link.blur();
  } catch(e){} // jshint ignore:line
});


document.querySelector('.barba-container').classList.add('is-visible');
Barba.Pjax.init();
Barba.Prefetch.init();
document.documentElement.classList.add('is-barba');
