import Barba from 'barba.js';
import on from '../utilities/on';

class Menu {

  constructor() {
    
    this.menu = document.querySelector('.js-menu');

    // handle toggle click
    on(this.menu, 'click', '.js-mobile-menu-toggle', (e) => {
      e.preventDefault();
      this.toggle();
    });

    // handle backdrop click
    on(this.menu, 'click', '.js-menu-backdrop', (e) => {
      e.preventDefault();
      this.open()
    });


    // handle mobile/desktop menu switch
    const mediaQueryList = window.matchMedia("(min-width: 62.5em)"); /* = 1000px */
    let removeTimeout;
    
    mediaQueryList.addListener(() => {
      this.menu.classList.add('is-transitions-disabled');

      if(mediaQueryList.matches) {
        
      }
    
      if(removeTimeout) {
        clearTimeout(removeTimeout);
      }
    
      removeTimeout = setTimeout(() => this.menu.classList.remove('is-transitions-disabled'), 300);
    });

  }

  open() {
    document.documentElement.classList.add('is-menu-open');
  }
  
  close() {
    document.documentElement.classList.remove('is-menu-open'); 
  }

  toggle() {
    document.documentElement.classList.toggle('is-menu-open');
  }

}


function init() {
  const menu = new Menu();

  Barba.Dispatcher.on('linkClicked', function() {
    menu.close();
  });
}

init();
