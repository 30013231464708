import Barba from 'barba.js';
import on from '../utilities/on';
import parseSrcset from 'parse-srcset';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

const CONTENT_ELEMENT      = document.getElementById('content');
const IMAGE_SELECTOR       = '.content :not(a):not(.js-no-zoom) > .o-media--type\\:image';
const IMAGESET_SELECTOR    = ':not(a):not(.js-no-zoom) > .o-media--type\\:image';
const GALLERY_PARENT_CLASS = '.content';
const PHOTOSWIPE_ELEMENT   = document.querySelector('.pswp');

let currentGalleryItems = null;

const PHOTOSWIPE_OPTIONS   = {
  index:     0,
  history:   false,
  shareEl:   false,
  bgOpacity: 0.85,

  getThumbBoundsFn(index) {

    // const thumbnail   = CONTENT_ELEMENT.querySelectorAll(IMAGE_SELECTOR)[index]; // find thumbnail
    // console.log("bounds", index, currentGalleryItems)
    const thumbnail = currentGalleryItems[index].sourceImage;
    const pos       = thumbnail.getBoundingClientRect();

    return {
      x: pos.left,
      y: pos.top + window.pageYOffset,
      w: pos.width
    };
  }

};

let currentGallery = null;

init();

function init() {

  on(CONTENT_ELEMENT, 'click', IMAGE_SELECTOR, function(e) {

    const options = Object.assign({}, PHOTOSWIPE_OPTIONS);
    const items   = getGalleryItems(this, parseSrcset);

    for(let i = 0, l = items.length; i < l; i++){
      if(items[i].sourceImageset == this) {
        options.index = i;
        break;
      }
    }

    currentGalleryItems = items;

    const gallery = new PhotoSwipe(PHOTOSWIPE_ELEMENT, PhotoSwipeUI_Default, items, options);

    gallery.listen('close', () => {
      currentGallery = null;
    });

    gallery.init();

    currentGallery = gallery;

  });

  Barba.Dispatcher.on('newPageReady', update);
  Barba.Dispatcher.on('initStateChange', () => {
    if(currentGallery !== null) {
      currentGallery.close();
    }
  });


  update();
}

function update() {
  const imagesets = CONTENT_ELEMENT.querySelectorAll(IMAGE_SELECTOR);
  for(let i = 0, l = imagesets.length; i < l; i++) {
    imagesets[i].classList.add('is-zoomable');
  }
}

function getGalleryItems(imageset) {

  let container = imageset.parentNode;

  while(container.parentNode && !container.classList.contains(GALLERY_PARENT_CLASS)) {
    container = container.parentNode;
  }

  const imagesets = container.querySelectorAll(IMAGESET_SELECTOR);
  const items = [];

  for (let i = 0, l = imagesets.length; i < l; i++) {
    const imagesetItem = imagesets[i];
    const img          = imagesetItem.querySelector('img:not(.o-media__placeholder)');

    if (img === null) {
      continue;
    }

    if(img.hasAttribute('srcset')) {
      const srcsetAttr = img.classList.contains('lazyload') ? 'data-srcset' : 'srcset';
      const srcset     = parseSrcset(img.getAttribute(srcsetAttr));

      let largest = null;

      for(let i = 0, l = srcset.length; i < l; i++) {
        if(!largest || srcset[i].w > largest.w) {
          largest = srcset[i];
        }
      }

      items.push({
        src:  largest.url,
        w:    largest.w,
        h:    largest.w * imagesetItem.offsetHeight / imagesetItem.offsetWidth,
        msrc: img.currentSrc,
        sourceImage: img,
        sourceImageset: imagesetItem,
      });
    }
  }

  return items;
}

