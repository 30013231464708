import Barba from 'barba.js';

class Logo {
  
  constructor() {
    this.logoLink     = document.querySelector('.js-logo-link');
    this.pattern      = document.querySelector('.js-logo-pattern');
    this.patternStyle = this.pattern.style;

    this.isAcceletating   = false;
    this.maxSpeed         = 1;
    this.currentSpeed     = 0;
    this.isFrameAvailable = true;
    this.lastFrameTime    = 0;
    this.round            = 15000;
    this.currentAngle     = 0;

    this.logoLink.addEventListener('mouseenter', () => {
      this.isAcceletating = true;
      this.lastFrameTime  = (new Date()).getTime();
      if (this.isFrameAvailable) {
        this.isFrameAvailable = false;
        window.requestAnimationFrame(this.rotate.bind(this));
      }
    });

    this.logoLink.addEventListener('mouseleave', () => {
      this.isAcceletating = false;
    });

    this.logoLink.addEventListener('click', function(e) {
      Barba.Pjax.goTo(this.getAttributeNS('http://www.w3.org/1999/xlink', 'href'));
      e.preventDefault();
    });
  }

  rotate() {
    const currentTime = (new Date()).getTime();
    const timeDelta   = currentTime - this.lastFrameTime;
    
    this.lastFrameTime = currentTime;

    if (this.isAcceletating) {
      this.currentSpeed = Math.min(this.maxSpeed, Math.max(0.1, 1.15 * this.currentSpeed));
    } else {
      this.currentSpeed = Math.max(this.currentSpeed - 0.01, 0);
    }

    if (this.currentSpeed <= 0 ) {
      this.isFrameAvailable = true;
      return;
    }
    
    this.currentAngle = (this.currentAngle + timeDelta / this.round * this.currentSpeed) % 1;
    this.patternStyle.transform = `rotate(${360 * this.currentAngle}deg)`;
    this.isFrameAvailable = false;
    window.requestAnimationFrame(this.rotate.bind(this));
  }

  accelerate() {
    this.isAcceletating = true;
    this.lastFrameTime  = (new Date()).getTime();
    if(this.isFrameAvailable) {
      this.isFrameAvailable = false;
      window.requestAnimationFrame(this.rotate.bind(this));
    }
  }

  brake() {
    this.isAcceletating = false;
  }
}

function init() {
  const logo     = new Logo();
  
  let brakeTimer = null;
  
  Barba.Dispatcher.on('linkClicked', () => logo.accelerate());

  Barba.Dispatcher.on('transitionCompleted', () => {
    if (brakeTimer) {
      clearTimeout(brakeTimer);
    }
    
    brakeTimer = setTimeout(() => logo.brake(), 150);
  });
}

init();
